var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"slider",class:[
    `slider-${_vm.id}`,
    { 'slider_is-full': _vm.isFull },
    { 'slider_multiple': _vm.isMultiple },
    { 'slider_dark': _vm.theme == 'dark' },
  ]},[_c('swiper',{ref:"Swiper",attrs:{"options":_vm.swiperOptions}},[_vm._t("default")],2),_c('div',{staticClass:"slider__controls",class:{ 'g-hidden': _vm.isFull }},[_c('div',{staticClass:"slider__count",class:`slider-${_vm.id}__count`,attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"button button_square slider__prev",class:[
        `slider-${_vm.id}__prev`,
        { 'button_transparent-dark': _vm.theme == 'dark' },
        { 'button_transparent': _vm.theme == 'light' }
      ],attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('svg',{attrs:{"width":"22","height":"13","viewBox":"0 0 22 13","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 12L1.5 6.5L7 1","stroke":"white","stroke-width":"1.5"}}),_c('path',{attrs:{"d":"M2 6.5L22 6.5","stroke":"white","stroke-width":"1.5"}})])]),_c('div',{staticClass:"button button_square slider__next",class:[
        `slider-${_vm.id}__next`,
        { 'button_transparent-dark': _vm.theme == 'dark' },
        { 'button_transparent': _vm.theme == 'light' }
      ],attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('svg',{attrs:{"width":"22","height":"13","viewBox":"0 0 22 13","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15 1L20.5 6.5L15 12","stroke":"white","stroke-width":"1.5"}}),_c('path',{attrs:{"d":"M20 6.5H0","stroke":"white","stroke-width":"1.5"}})])])]),_c('div',{staticClass:"slider__bullets",class:[`slider-${_vm.id}__bullets`, { 'g-hidden': _vm.isFull }]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }